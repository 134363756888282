<template>
    <div>
        <portal v-if="activated" to="automations-section-header">
            <div class="flex flex-grow items-center justify-between">
                <div class="txt-18 text-gray-1000">
                    {{ automation.name }}
                </div>
                <a v-if="isAutomationsTestingAccount(account.name)" :href="`/automation/${automation.id}/report?autmReportingNew`" class="btn btn-secondary ml-auto mr-4">
                    Nový design
                </a>
                <router-link :to="{name: 'automationsIndex'}" class="btn btn-tertiary items-center">
                    <icon class="mr-2 -ml-1" icon="caret-left-solid" />
                    <div class="-mr-1">
                        {{ $t('common.back') }}
                    </div>
                </router-link>
            </div>
        </portal>

        <div>
            <div v-if="!reportLoaded" class="w-4 h-4 mt-4 double-loader" />

            <template v-else>
                <section class="flex flex-col lg:flex-row mt-1 mb-2.5 -mx-5 lg:space-x-3">

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'send'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-sail-500" icon="paper-plane-solid" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.delivered') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ numberToLocale(stats['total']['send']) }}
                                </div>
                                <div class="txt-13 text-gray-900">
                                    {{ stats['total']['delivery_rate'] }}%
                                </div>
                            </div>
                        </div>

                        <div class="tw-bar mt-3">
                            <div class="meter bg-sail-500" role="progressbar" :aria-valuenow="stats['total']['delivery_rate']" :aria-valuemin="0" :aria-valuemax="100" :style="`width: ${stats['total']['delivery_rate'] <= 100 ? stats['total']['delivery_rate'] : 100}%;`" />
                        </div>

                    </router-link>

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'open'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-teal-500" icon="envelope-open" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.opens') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ stats['total']['open_rate'] }} %
                                </div>
                                <div class="txt-13 text-gray-900">
                                    {{ numberToLocale(stats['total']['open']) }}
                                </div>
                            </div>
                        </div>

                        <div class="tw-bar mt-3">
                            <div class="meter bg-teal-500" role="progressbar" :aria-valuenow="stats['total']['open_rate']" :aria-valuemin="0" :aria-valuemax="100" :style="`width: ${stats['total']['open_rate'] <= 100 ? stats['total']['open_rate'] : 100}%;`" />
                        </div>

                    </router-link>

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'click'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-melrose-500" icon="hand-pointer-solid" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.clicks') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ stats['total']['click_rate'] }} %
                                </div>
                                <div class="txt-13 text-gray-900">
                                    {{ numberToLocale(stats['total']['click']) }}<span v-if="stats['total']['click']"> / <abbr v-tooltip.top="$t('automations.report.ctr_help')"><span>CTR</span> {{ stats['total']['ctr'] }}%</abbr></span>
                                </div>
                            </div>
                        </div>

                        <div class="tw-bar mt-3">
                            <div class="meter bg-melrose-300" role="progressbar" :aria-valuenow="stats['total']['ctr']" :aria-valuemin="0" :aria-valuemax="100" :style="`width: ${stats['total']['ctr'] <= 100 ? stats['total']['ctr'] : 100}%;`" />
                            <div class="meter bg-melrose-700" role="progressbar" :aria-valuenow="stats['total']['click_rate']" :aria-valuemin="0" :aria-valuemax="100" :style="`width: ${stats['total']['click_rate'] <= 100 ? stats['total']['click_rate'] : 100}%;`" />
                        </div>

                    </router-link>

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'bounce'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-orange-500" icon="times-solid" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.bounced') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ stats['total']['bounce_rate'] }} %
                                </div>
                                <div class="txt-13 text-gray-900">
                                    {{ numberToLocale(stats['total']['bounce']) }}
                                </div>
                            </div>
                        </div>

                        <div class="tw-bar mt-3">
                            <div class="meter bg-orange-500" role="progressbar" :aria-valuenow="stats['total']['bounce_rate']" :aria-valuemin="0" :aria-valuemax="100" :style="`width: ${stats['total']['bounce_rate'] <= 100 ? stats['total']['bounce_rate'] : 100}%;`" />
                        </div>

                    </router-link>

                </section>

                <section v-if="Object.keys(stats['emails']).length" class="overflow-x-auto mb-8">
                    <div class="table tw-table no-stripes max-w-full">
                        <div class="table-header-group">
                            <div class="table-row">
                                <div class="table-cell txt-13 semibold">
                                    {{ $t('automations.report.email') }}
                                </div>
                                <div class="table-cell txt-13 semibold">
                                    {{ $t('automations.report.open_rate') }}
                                </div>
                                <div class="table-cell txt-13 semibold">
                                    {{ $t('automations.report.click_rate') }}
                                </div>
                            </div>
                        </div>
                        <div v-for="(email, index) in stats['emails']" :key="`automation-action-${index}`" class="table-row-group">
                            <div class="table-row">
                                <template>
                                    <div class="table-cell w-1/3 text-gray-1000 txt-14">
                                        <div class="flex items-center">
                                            <span class="icon-circle mr-3 flex-none">{{ index + 1 }}</span>
                                            {{ email['name'] }}
                                        </div>
                                    </div>
                                    <div class="table-cell text-gray-1000 w-1/3">
                                        <div v-if="email['open_rate']" class="flex items-center space-x-8">
                                            <span class="text-gray-1000 txt-14 whitespace-nowrap">{{ email['open_rate'] }} %</span>
                                            <div class="tw-bar">
                                                <div role="progressbar" :aria-valuenow="email['open_rate']" :aria-valuemin="0" :aria-valuemax="100" class="meter bg-teal-500" :style="`width: ${email['open_rate'] <= 100 ? email['open_rate'] : 100}%;`" />
                                            </div>
                                        </div>
                                        <template v-else>
                                            {{ $t('automations.report.no_data') }}
                                        </template>
                                    </div>
                                    <div class="table-cell text-gray-1000 w-1/3">
                                        <div v-if="email['click_rate']" class="flex items-center space-x-8">
                                            <span class="text-gray-1000 txt-14 whitespace-nowrap">{{ email['click_rate'] }} % / <abbr v-tooltip.top="$t('automations.report.ctr_help')"><span>CTR</span> {{ email['ctr'] }}%</abbr></span>
                                            <div class="tw-bar">
                                                <div role="progressbar" :aria-valuenow="email['ctr']" :aria-valuemin="0" :aria-valuemax="100" class="meter bg-melrose-300" :style="`width: ${email['ctr'] <= 100 ? email['ctr'] : 100}%;`" />
                                                <div role="progressbar" :aria-valuenow="email['click_rate']" :aria-valuemin="0" :aria-valuemax="100" class="meter bg-melrose-700" :style="`width: ${email['click_rate'] <= 100 ? email['click_rate'] : 100}%;`" />
                                            </div>
                                        </div>
                                        <template v-else>
                                            {{ $t('automations.report.no_data') }}
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </section>

                <section v-if="stats['total']['sms_sent']" class="flex flex-col lg:flex-row mb-4 -mx-5 lg:space-x-3">

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'sms_sent'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-sail-500" icon="paper-plane-solid" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.sms_sent') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ numberToLocale(stats['total']['sms_sent']) }}
                                </div>
                            </div>
                        </div>

                    </router-link>

                    <router-link :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'sms_inv_nr'}}" class="w-full lg:w-1/4 p-5 hover-box hover:no-underline">

                        <div class="flex space-x-4">
                            <div class="bg-gray-200 rounded-full flex items-center justify-center" style="height: 50px; width: 50px">
                                <icon class="text-red-500" icon="times-solid" size="large" />
                            </div>
                            <div class="flex flex-col">
                                <div class="txt-13 semibold text-gray-800 lh-15">
                                    {{ $t('automations.report.invalid_number') }}
                                </div>
                                <div class="txt-35 lh-37 bold text-gray-1000">
                                    {{ numberToLocale(stats['total']['sms_inv_nr']) }}
                                </div>
                            </div>
                        </div>

                    </router-link>
                </section>

                <section class="mb-8 bb">
                    <div class="mb-5 flex space-x-4 items-center">
                        <div class="txt-14 text-gray-1000">
                            {{ $t('automations.report.chart_title') }}
                        </div>
                        <a class="txt-12 text-green-600 semibold" href="#" @click.prevent="graphHidden = !graphHidden">{{ graphHidden ? $t('charts.show_graph') : $t('charts.hide_graph') }}</a>
                    </div>
                    <am-chart
                            :class="{'hidden': graphHidden}"
                            type="line"
                            :height="250"
                            :data="graphStats"
                            :options="{category: 'date', legend: {useDefaultMarker: true}, series: [{title: $t('automations.report.chart_sent'), color: '#CCCCCC', value: 'send'}, 
                                                                                                    {title: $t('automations.report.chart_opens'), color: '#58C0DE', value: 'open'},
                                                                                                    {title: $t('automations.report.chart_clicks'), color: '#8B8AF3', value: 'click'}]}" />

                </section>

                <section class="flex flex-col lg:flex-row pb-2 mb-9.5 bb lg:space-x-16">
                    <div class="w-full lg:w-1/3">
                        <div class="table border-collapse table-fixed w-full txt-13 semibold text-gray-800">
                            <div class="table-row border-b border-gray-300">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="users-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.recipients_total_help')">
                                            {{ $t('automations.report.recipients_total') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['base']['subscribers']) }}
                                </div>
                            </div>
                            <div class="table-row border-b border-gray-300">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="play-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.active_subscribers_help')">
                                            {{ $t('automations.report.active_subscribers') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['base']['active']) }}
                                </div>
                            </div>
                            <div class="table-row border-b lg:border-b-0 border-gray-300">
                                <div class="table-cell w-min w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="check-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.finished_subscribers_help')">
                                            {{ $t('automations.report.finished_subscribers') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['base']['finished']) }}
                                </div>
                            </div>
                            <div class="lg:hidden table-row border-b border-gray-300">
                                <div class="table-cell w-min w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="times-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.bounces_help')">
                                            {{ $t('automations.report.bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['total']['bounce']) }}
                                </div>
                            </div>
                            <div class="lg:hidden table-row border-b border-gray-300">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="dot-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.soft_bounces_help')">
                                            {{ $t('automations.report.soft_bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['total']['soft_bounce']) }}
                                </div>
                            </div>
                            <div class="lg:hidden table-row">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="stop-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.hard_bounces_help')">
                                            {{ $t('automations.report.hard_bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['total']['hard_bounce'] + stats['total']['out_of_band']) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hidden lg:block lg:w-1/3">
                        <div class="table border-collapse w-full table-fixed txt-13 semibold text-gray-800">
                            <div class="table-row border-b border-gray-300">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="times-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.bounces_help')">
                                            {{ $t('automations.report.bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['total']['bounce']) }}
                                </div>
                            </div>
                            <div class="table-row border-b border-gray-300">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="dot-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.soft_bounces_help')">
                                            {{ $t('automations.report.soft_bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ numberToLocale(stats['total']['soft_bounce']) }}
                                </div>
                            </div>
                            <div class="table-row">
                                <div class="table-cell w-full py-3">
                                    <div class="flex items-center">
                                        <icon icon="stop-circle-solid" size="large" class="inline text-gray-700 mr-6" />
                                        <abbr v-tooltip.top="$t('automations.report.hard_bounces_help')">
                                            {{ $t('automations.report.hard_bounces') }}
                                        </abbr>
                                    </div>
                                </div>
                                <div class="table-cell w-min py-3 txt-14 text-gray-1000 font-normal">
                                    {{ (stats['total']['hard_bounce'] + stats['total']['out_of_band']) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10">

                    <div class="w-full lg:w-1/2">

                        <h5 class="mb-2 lg:mb-6.5 text-gray-1000 font-normal">
                            {{ $t('automations.report.last_10_users') }}
                        </h5>

                        <div v-if="recentUsers && Object.keys(recentUsers).length" class="table border-collapse w-full mb-5">
                            <div v-for="(user, index) in recentUsers" :key="`user-${index}`" class="table-row border-b border-gray-300">
                                <div class="table-cell semibold txt-13 w-full">
                                    <a href="#" @click.prevent="showSubscriberModal(user.email)">{{ user.email }}</a>
                                </div>
                                <div class="table-cell py-3 whitespace-nowrap">
                                    <div class="txt-14 text-gray-1000">
                                        {{ getNumberDate(user.timestamp) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" class="mx-auto" alt="Empty users" style="width: 100px">
                            <div class="mt-1 txt-13 text-gray-800">
                                {{ $t('automations.report.empty_users') }}
                            </div>
                        </div>

                        <router-link v-if="automation.recent && Object.keys(automation.recent).length" :to="{name: 'automationsActivities', params: {automation: automation.id, activity: 'send'}}" class="btn btn-tertiary">
                            {{ $t('automations.report.all_users_button') }}
                        </router-link>

                    </div>

                    <div class="w-full lg:w-1/2">

                        <h5 class="mb-2 lg:mb-6.5 text-gray-1000 font-normal">
                            {{ $t('automations.report.latest_events') }}
                        </h5>

                        <div v-if="Object.keys(activity).length" class="table border-collapse w-full mb-5">
                            <div v-for="(event, index) in activity" :key="`event-${index}`" class="table-row border-b border-gray-300">
                                <template v-if="event.timestamp && Object.keys(event.props).length">
                                    <div class="table-cell align-middle semibold txt-13 w-full">
                                        <div class="flex">
                                            <icon v-tooltip.top="$t(`automations.report.events.${getPropsType(event.props)}`)" :icon="icons[getPropsType(event.props)]" size="large" class="min-w-min px-2.5" :class="iconsColors[getPropsType(event.props)]" />
                                            <a href="#" @click.prevent="showSubscriberModal(event.email)">{{ event.email }}</a>
                                        </div>
                                    </div>
                                    <div class="table-cell py-3 whitespace-nowrap">
                                        <div class="txt-14 text-gray-1000">
                                            {{ getNumberDate(event.timestamp) }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-file.png" class="mx-auto" alt="Empty events" style="width: 100px">
                            <div class="mt-1 text-center txt-13 text-gray-800">
                                {{ $t('automations.report.empty_events') }}<br>
                                <span class="txt-12">{{ $t('automations.report.empty_events_note') }}</span>
                            </div>
                        </div>

                        <div v-if="Object.keys(activity).length && Object.keys(activity).length < 10" class="txt-12 grey-70 text-center">
                            {{ $t('automations.report.events_note') }}
                        </div>

                    </div>

                </section>
            </template>
        </div>

        <tw-modal ref="subscriberDetail" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">

            <template #default="slotProps">

                <subscriber-form :modal="slotProps" @contactDeleted="contactDeleted" />

            </template>

        </tw-modal>
    </div>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'
import toTemplate from '@mixins/toTemplate'
import permissions from '@mixins/permissions'

import icons from '@component/Automation/icons.json'
import iconsColors from '@component/Automation/icons-colors.json'

export default {
    name: 'Report',

    mixins: [toTemplate, permissions],

    store,

    props: ['automation', 'recentUsers'],

    data() {
        return {
            activated: true,
            icons: icons,
            iconsColors: iconsColors,

            sending: false,
            success: null,
            message: null,

            graphHidden: false
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('automationsModule', [
            'reportLoaded', 'stats', 'graphStats', 'activity'
        ])
    },

    activated() {
        this.activated = true
    },
    deactivated() {
        this.activated = false
    },

    async beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('automationsModule/loadReport', {automationId: to.params.automation})
        })
    },

    methods: {
        showSubscriberModal(email) {
            this.$refs['subscriberDetail'].showModalRawData({
                email: email
            })
        },

        contactDeleted(email) {
            this.$message.success(this.$t('appforms.subscriber.contact_deleted_alert', {contact: email}))
        },

        getPropsType(propsRaw) {
            return typeof propsRaw === 'string' || propsRaw instanceof String ? JSON.parse(propsRaw)['type']  : propsRaw['type']
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "~sass/variables";

    abbr {
        border-bottom: 1px dashed $grey-70;
    }
</style>