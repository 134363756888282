<template>
    <div @keyup.enter="verifyCode">

        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
            {{ $t('auth.2fa.header') }}
        </div>

        <div class="text-auth-base text-auth-gray-500 text-center" v-html="$t('auth.2fa.auth.anotation')" />

        <hr class="mt-5 mb-6">

        <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

        <OtpInput
                ref="otpInput"
                input-classes="mt-2 rounded-md text-center border bg-auth-gray-50 border-auth-gray-300 mx-1 sm:mx-2 focus:ring-2 focus:ring-offset-2 focus:ring-auth-green-500 focus:outline-none transition duration-200 ease-in-out"
                separator=""
                :num-inputs="numberOfInputs"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange" />

        <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="verifyCode">
            <span class="text-auth-base">{{ $t('auth.2fa.verify_key_cta') }}</span>
        </FormButton>

        <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
            <span>{{ $t('auth.2fa.auth.cant_login') }}&nbsp;</span>
            <router-link :to="{name: 'twoFactorAuthRecovery'}" class="cursor-pointer hover:no-underline text-auth-green-500">
                {{ $t('auth.2fa.auth.enter_recovery_code') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import FormButton from '@component/Auth/FormButton.vue'
import OtpInput from '@component/Auth/OtpInput.vue'

import hasher from '@mixins/hasher'
import fingerprint from '@mixins/fingerprint'
import { validate } from 'vee-validate'

export default {
    name: 'AuthLayout',

    mixins: [hasher, fingerprint],

    components: {
        FormButton,
        OtpInput,
    },

    data() {
        return {
            loading: false,
            numberOfInputs: 6,
            verificationCode: null,
            errors: [],
            requestError: null,
        }
    },

    computed: {
        accountUrl() {
            return window.location.hostname
        }
    },

    watch: {
        verificationCode(val) {
            if(val.length === this.numberOfInputs) {
                this.verifyCode()
            }
        }
    },

    methods: {
        handleOnChange(val) {
            this.verificationCode = val.toString()
        },
        async verifyCode() {
            try {
                const valid = await this.validateForm()
                if(!valid)
                    return

                this.loading = true
                const fingerprint = await this.getFingerprint()
                const response = await this.$http.post('/2fa/challenge-authenticator', {
                    verification_code: this.verificationCode,
                    duid: fingerprint,
                })
                if(response.status === 204) {
                    window.location.href = '/'
                }
                else if(response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl.replace('http://', 'https://')
                }
                this.loading = false
            } catch(e) {
                this.requestError = e.message
                this.loading = false
                this.$refs.otpInput.clearInput()
            }
        },
        async validateForm() {
            const verificationCode = await validate(this.verificationCode, 'required')
            this.errors = verificationCode.errors

            return verificationCode.valid
        }
    }
}
</script>

<style scoped lang="scss">

</style>